<template>
  <div class="courseUser"> 
    <div class="pageContol listWrap templateList">
        <div class="framePage">
            <div class="framePage-title">
                <span>
                <em>当前位置：</em>
                <a href="javascript:;">数据统计</a>
                <i>></i>
                <a href="javascript:;" @click="init" class="cur-a">问卷统计</a>
                </span>
            </div>
            <div class="framePage-body">
                <div class="operationControl " style="display:flex;flex-direction: column;align-items: flex-start">
                    <div class="searchbox" style="margin-bottom:15px">
                        <div title="班级编码" class="searchboxItem ci-full">
                            <span class="itemLabel" >班级编码:</span>
                            <el-input
                                    v-model="selectData.projectCode"
                                    type="text"
                                    size="small"
                                    clearable
                                    placeholder="请输入班级编码"
                            />
                        </div>
                        <div title="班级名称" class="searchboxItem ci-full">
                            <span class="itemLabel" >班级名称:</span>
                            <el-input
                                    v-model="selectData.projectName"
                                    type="text"
                                    size="small"
                                    clearable
                                    placeholder="请输入班级编码"
                            />
                        </div>
                        <div title="行政区划" class="searchboxItem ci-full">
                            <span class="itemLabel">行政区划:</span>
                            <el-cascader
                            v-model="selectData.areaId"
                            :options="areatreeList"
                            :props="propsarea"
                            :disabled="disabled"
                            clearable
                            filterable
                            size="small"
                            ></el-cascader>
                        </div>
                         <div title="培训类型" class="searchboxItem ci-full">
              <span class="itemLabel">培训类型:</span>
              <el-cascader
                :props="{emitPath:false,value:'id',label:'label',children:'children',checkStrictly: true}"
                v-model="selectData.trainTypeId"
                size="small"
                clearable
                :options="trainTypeList"
                @change="handleTrainType"
              ></el-cascader>
            </div>
                    </div>
                     <div class="searchbox">
                        <div title="开班时间:" class="searchboxItem ci-full">
                            <span class="itemLabel" >开班时间:</span>
                            <el-date-picker
                            clearable
                            size="small"
                            v-model="selectData.userTime"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd"
                            ></el-date-picker>
                        </div>
                        <div title="结束时间:" class="searchboxItem ci-full">
                            <span class="itemLabel">结束时间:</span>
                            <el-date-picker
                            clearable
                            size="small"
                            v-model="selectData.endTime"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd"
                            ></el-date-picker>
                        </div>
                        <div class="df">
                            <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
                        </div>
                    </div>
                </div>
                 <div class="framePage-scroll">
                    <div class="ovy-a">

                      <el-table
                        ref="multipleTable"
                        :data="tableData"
                        :height="tableHeight"
                        size="small"
                        tooltip-effect="dark"
                        style="width: 100%"
                        :header-cell-style="tableHeader"
                        stripe
                      >
                        <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed/>
                          <el-table-column label="班级编码" align="left" prop="projectCode" show-overflow-tooltip width="180" fixed/>
                          <el-table-column label="班级名称" align="left" show-overflow-tooltip prop="projectName" min-width="200"/>
                        <el-table-column label="行政区划" align="left" show-overflow-tooltip prop="areaNamePath" min-width="150"/>

                        <el-table-column label="培训类型" align="left" show-overflow-tooltip prop="trainTypeNamePath" min-width="150"/>
                        
                          <el-table-column label="开始时间" align="left" show-overflow-tooltip prop="startDate" min-width="130">
                              <template slot-scope="scope">
                                <span>{{scope.row.startDate|momentDate}}</span>
                              </template>
                            </el-table-column>
                            <el-table-column label="结束时间" align="left" show-overflow-tooltip prop="endDate" min-width="130">
                              <template slot-scope="scope">
                                <span>{{scope.row.endDate|momentDate}}</span>
                              </template>
                            </el-table-column>
                            <el-table-column label="班级状态" align="left" show-overflow-tooltip prop="state" />
                            <el-table-column label="班级总人数" align="right" show-overflow-tooltip prop="userNum" />
                            <el-table-column label="学习人数" align="right" show-overflow-tooltip prop="studyNum" />
                            <el-table-column label="结业人数" align="right" show-overflow-tooltip prop="graduationNum" />
                            <el-table-column label="填写问卷人数" align="right" show-overflow-tooltip prop="answerNum" minWidth="120"/>
                        <el-table-column label="操作" width="320px" align="center" fixed="right">
                          <template slot-scope="scope">
                            <el-button
                              type="text"
                              size="mini"
                              style="padding:0 5px"
                              @click="Dailysummary(scope.row.projectCourseId,scope.row.projectId)"
                            >每日问卷汇总</el-button>
                            <el-button
                              type="text"
                              size="mini"
                              style="padding:0 5px"
                              @click="goDetail(scope.row)"
                            >期末问卷汇总</el-button>
                            <el-button
                              type="text"
                              size="mini"
                              style="padding:0 5px"
                              @click="Studentquestionnaire(scope.row)"
                            >查看学员问卷</el-button>
                          </template>
                        </el-table-column>
                        <Empty slot="empty" />
                      </el-table>
                    </div>
                  </div>
                  <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "set/courseUser",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  props: ["name"],
  data() {
    return {
      selectData:{
        areaId: "",
        trainTypeId:'',
        projectName: "",
        projectCode: "",
        userTime:'',
        endTime:'',
        startDateBegin:'',
startDateEnd:'',
endDateBegin:'',
endDateEnd:'',

      },
      areatreeList: [],
      trainTypeList:[],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true
      },
    };
  },
  computed: {},
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getTableHeight();
      this.getareatree();
      this.getTraintype();
      this.getData();
    },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then(ret => {
        this.areatreeList = ret.data;
      });
    },
     /**
     * 获取培训类型
     */
    getTraintype() {
      this.$post("/sys/category/train-type/tree").then(res => {
        if (res.status == "0") {
          this.trainTypeList = res.data;
        }
      });
    },
    getData(pageNum) {
      if(this.selectData.userTime&&this.selectData.userTime.length>0){
        this.selectData.startDateBegin=this.selectData.userTime[0];
         this.selectData.startDateEnd=this.selectData.userTime[1];
      }else{
         this.selectData.startDateBegin="";
         this.selectData.startDateEnd="";
      }
      if(this.selectData.endTime&&this.selectData.endTime.length>0){
        this.selectData.endDateBegin=this.selectData.endTime[0];
         this.selectData.endDateEnd=this.selectData.endTime[1];
      }else{
         this.selectData.endDateBegin="";
         this.selectData.endDateEnd="";
      }
      const params = {
        ...this.selectData,
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      this.doFetch({
        url: "/survey/sjzcx/project/page",
        params,
        pageNum
      });
    },
    //每日问卷汇总
    Dailysummary(projectCourseId,projectId) {
      this.$router.push({
        path: "/web/set/questionnaireDailysummary",
        query: {
          projectCourseId,
          projectId
        }
      });
    },
    //学员问卷
    Studentquestionnaire(row) {
      this.$router.push({
        path: "/web/set/questionnaireStudentList",
         query: {
                  editInfo: JSON.stringify({
                   row
                  }),
                },
      });
    },
    goDetail(row) {
     this.$router.push({
        path: "/web/set/questionnaireDailysummaryDetail",
        query: {
           editInfo: JSON.stringify({
                   row
                  }),
                  stu:'all'
        }
      })
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 4) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    doExport() {
        const params = {
            ...this.selectData
        };
        this.$post("/biz/buy/stat/courseUseExport", params).then(res => {
            if (res.status === "0") {
                window.open(res.data);
            }
        });
    }
  },
  mounted: function() {}
};
</script>

<style lang="less" scope>
.courseUser{
  height: 100%;
}
.el-textarea {
  resize: none;
  .el-textarea__inner {
    height: 200px;
  }
}
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
    }
  }
}
.btnBox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.el-table th.is-left {
  >.cell {
    text-align: left;
  }
}
.el-table th.is-right {
  >.cell {
    text-align: right;
  }
}
</style>
